import React, { useEffect } from 'react';
import { AppCore } from 'case-web-app-core';
import RIVMHeader from './components/header/RIVMHeader';
import { navbarConfig } from './configs/navbar';
import { footerConfig } from './configs/footer';
import { pagesConfig } from './configs/pages';
import { appConfig } from './configs/appConfig';
import { useTranslation } from 'react-i18next';
import EntryCodeValidator from './components/survey/EntryCodeValidator';
import Address from './components/survey/Address';
import IsInSelfSwabbingStudy from './components/extensions/IsInSelfSwabbingStudy';
import { nl } from 'date-fns/locale';
import IgasonderzoekControlRegistration from './components/extensions/IgasonderzoekControlRegistration';
import IgasonderzoekSurveys from './components/extensions/IgasonderzoekSurveys';


if (process.env.REACT_APP_DEFAULT_INSTANCE) {
  appConfig.instanceId = process.env.REACT_APP_DEFAULT_INSTANCE;
}

const extensions = [
  { name: 'isInSelfSwabbingStudy', component: IsInSelfSwabbingStudy },
  { name: 'igasonderzoekControlRegistration', component: IgasonderzoekControlRegistration },
  { name: 'igasonderzoekSurveys', component: IgasonderzoekSurveys },
]

export const dateLocales = [
  { code: 'nl', locale: nl, format: 'dd-MM-yyyy' },
];



export const customSurveyResponseComponents = [
  {
    name: 'input:entryCodeValidation',
    component: EntryCodeValidator,
  },
  {
    name: ':address',
    component: Address,
  },
];

const App: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language !== 'nl') {
      i18n.changeLanguage('nl');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])


  return (
    <React.Fragment>
      <AppCore
        appConfig={appConfig}
        customHeader={<RIVMHeader />}
        hideDefaultHeader={true}
        navbarConfig={navbarConfig}
        pagesConfig={pagesConfig}
        footerConfig={footerConfig}
        dateLocales={dateLocales}
        customSurveyResponseComponents={customSurveyResponseComponents}
        extensions={extensions}
      />
    </React.Fragment>
  );
};

export default App;
