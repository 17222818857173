import { PageConfig, PagesConfig } from "case-web-app-core/build/types/pagesConfig";
import { aboutSelftestStudyPage } from "./pages/aboutSelfteststudy";
import { igasonderzoekPage } from "./pages/igasonderzoek";
import { surveyOverviewPage } from "./pages/surveyOverviewPage";

const defaultRoutes = {
  auth: "/home",
  unauth: "/welcome",
  studyPage: "/home",
  surveyPage: "/surveys"
};

const landingPage: PageConfig = {
  path: "/welcome",
  pageKey: "landing",
  hideWhen: 'auth',
  rows: [
    {
      key: 'teaserImage',
      fullWidth: true,
      columns: [
        {
          key: 'col',
          className: 'w-100 p-0',
          items: [
            {
              itemKey: 'teaserImage',
              config: {
                type: 'teaserImage',
                image: {
                  url: '/images/login_background.jpg',
                  height: 367,
                  backgroundPosition: '47% 27%',
                }
              }
            }
          ]
        }
      ]
    },
    {
      key: 'loginRow',
      containerClassName: '',
      columns: [
        {
          key: 'aboutCardCol',
          className: 'col-12 col-md-6 col-lg-4 mt-3',
          items: [
            {
              itemKey: 'aboutCard',
              config: {
                type: 'imageCard',
                showActionBtn: true,
                action: {
                  type: 'navigate',
                  value: '/about',
                },
                imageSrc: '/images/about_image.jpg',
              }
            }
          ]
        },
        {
          key: 'resultsCol',
          className: 'col-12 col-md-6 col-lg-4 mt-3',
          items: [{
            itemKey: 'resultsCard',
            className: 'h-100',
            config: {
              type: 'imageCard',
              showActionBtn: true,
              action: {
                type: 'navigate',
                value: '/results',
              },
              imageSrc: '/images/ANP-7666051-1024.jpg',
            }
          }]
        },
        {
          key: 'signupCol',
          className: 'col-12 col-md-6 col-lg-4 mt-3',
          items: [{
            itemKey: 'signupCard',
            className: 'h-100',
            config: {
              type: 'imageCard',
              showActionBtn: true,
              action: {
                type: 'openDialog',
                value: 'signup',
              },
              imageSrc: '/images/iStock-1311747918_small.jpg',
            }
          }]
        },
      ]
    },
    {
      key: 'videoRow',
      containerClassName: 'mb-3',
      columns: [
        {
          key: 'videoCol',
          className: 'col-12 offset-0 offset-lg-2 col-lg-8 mt-3',
          items: [
            {
              itemKey: 'video',
              className: 'bg-grey-7 w-100 h-100 text-light justify-content-center text-center d-flex align-items-center',
              config: {
                type: 'video',
                posterUrlKey: 'poster',
                fallbackTextKey: 'fallback',
                videoSources: [
                  { type: 'video/mp4', urlKey: 'mp4' },
                  { type: 'video/mxf', urlKey: 'mxf' },
                  { type: 'video/webm', urlKey: 'webm' },
                ]
              }
            }
          ]
        },

      ]
    }
  ]
}



const settingsPage: PageConfig = {
  path: "/settings",
  hideWhen: 'unauth',
  pageKey: "settings",
  rows: [
    {
      key: 'mainRow',
      containerClassName: 'mt-3',
      columns: [
        {
          key: 'settingsCol',
          className: 'col-12 col-md-10 col-lg-6 offset-lg-1 offset-0',
          items: [{
            itemKey: 'accountSettings',
            config: {
              type: 'accountSettings',
              hideProfileSettings: false,
            }
          },
          {
            itemKey: 'communicationSettings',
            config: {
              type: 'communicationSettings',
              hideLanguageSelector: true
            }
          }
          ]
        },
        {
          key: 'helpCol',
          className: 'col-12 col-md-10 col-lg-4 pt-3 pt-lg-0',
          items: [
            {
              itemKey: 'references',
              config: {
                type: 'linkList',
                links: [
                  {
                    linkKey: 'faqLink',
                    type: 'internal',
                    value: '/faq'
                  },
                  {
                    linkKey: 'privacyStatementLink',
                    type: 'internal',
                    value: '/privacy'
                  },
                  {
                    linkKey: 'contactLink',
                    type: 'internal',
                    value: '/contact'
                  }
                ]
              }
            },
            {
              itemKey: 'systemInfo',
              config: {
                type: 'systemInfo',
                showBrowserInfo: true
              }
            }
          ]
        }
      ]
    },
    {
      key: 'deleteRow',
      containerClassName: 'mb-3',
      columns: [
        {
          key: 'deleteCol',
          className: 'col-12 col-md-10 col-lg-6 offset-lg-1 offset-0',
          items: [
            {
              itemKey: 'deleteAccount',
              config: {
                type: 'deleteAccount'
              }
            }
          ]
        }
      ]
    }
  ]
}

const resultsPage: PageConfig = {
  path: "/results",
  pageKey: "results",
  rows: [{
    key: 'contentRow',
    containerClassName: 'p-0',
    rowClassNameOverride: 'p-0',
    fullWidth: true,
    columns: [{
      key: 'contentCol',
      className: 'w-100 p-0',
      items: [
        {
          itemKey: 'markdownItem',
          config: {
            type: 'iframe',
            url: process.env.REACT_APP_RESULTS_PAGE_CONTENT_URL ? process.env.REACT_APP_RESULTS_PAGE_CONTENT_URL : 'https://dashboard.infectieradar.nl',
            scrolling: false,
          }
        }
      ]
    },
    ]
  }]
}

const faqPage: PageConfig = {
  path: "/faq",
  pageKey: "faq",
  rows: [{
    "key": "content",
    "fullWidth": false,
    "containerClassName": "my-3",
    "columns": [
      {
        "key": "mainCol",
        "className": "col-12 col-lg-8",
        "items": [
          {
            "itemKey": "topItem",
            "className": "",
            "config": {
              "type": "simpleCard",
              "variant": "h2"
            }
          },
          {
            "itemKey": "items",
            "className": "",
            "config": {
              "type": "accordionList",
              "accordionCtrlsKey": "accordionControls"
            }
          }
        ]
      },
      {
        "key": "cardSignupCol",
        "className": "col-12 col-lg-4 mt-3 mt-lg-0",
        "items": [
          {
            "itemKey": "cardBeforeLogin",
            hideWhen: 'auth',
            "className": "",
            "config": {
              "type": "imageCard",
              "action": {
                "type": "openDialog",
                "value": "signup"
              },
              "imageSrc": "/images/ANP-407855406-1024.jpg",
              "showActionBtn": true
            }
          },
          {
            "itemKey": "cardAfterLogin",
            hideWhen: 'unauth',
            "className": "",
            "config": {
              "type": "imageCard",
              "action": {
                "type": "navigate",
                "value": "/home"
              },
              "imageSrc": "/images/ANP-407855406-1024.jpg",
              "showActionBtn": true
            }
          }
        ]
      }
    ]
  }]
}

const aboutPage: PageConfig = {
  path: "/about",
  pageKey: "about",
  rows: [{
    key: 'topImage',
    fullWidth: true,
    columns: [{
      key: 'col',
      className: 'w-100 p-0',
      items: [{
        itemKey: 'topImage',
        config: {
          type: 'teaserImage',
          image: {
            url: '/images/ANP-371602781-1024.jpg',
            backgroundPosition: '50% 20%',
            height: 367,
          }
        }
      }]
    }]
  }, {
    key: 'contentRow',
    fullWidth: false,
    containerClassName: 'my-3',
    columns: [
      {
        key: 'markdownCol',
        className: 'col-12 col-lg-8',
        items: [{
          itemKey: 'content',
          config: {
            type: 'markdown',
            markdownUrl: '/markdowns/about.md'
          }
        }]
      },
      {
        key: 'cardCol',
        className: 'col-12 col-lg-4 mt-3 mt-lg-0',
        items: [
          {
            "itemKey": "cardBeforeLogin",
            hideWhen: 'auth',
            "className": "",
            "config": {
              "type": "imageCard",
              "action": {
                "type": "openDialog",
                "value": "signup"
              },
              "imageSrc": "/images/ANP-407855406-1024.jpg",
              "showActionBtn": true
            }
          },
          {
            "itemKey": "cardAfterLogin",
            hideWhen: 'unauth',
            "className": "",
            "config": {
              "type": "imageCard",
              "action": {
                "type": "navigate",
                "value": "/home"
              },
              "imageSrc": "/images/ANP-407855406-1024.jpg",
              "showActionBtn": true
            }
          },
          {
            itemKey: 'systemInfo',
            config: {
              type: 'systemInfo',
              showBrowserInfo: false
            }
          }
        ]
      },
    ]
  }]
}

const privacyPage: PageConfig = {
  path: "/privacy",
  pageKey: "privacy",
  rows: [{
    key: 'content',
    containerClassName: "my-3 min-vh-50",
    columns: [{
      key: "pCol",
      className: "col-12 col-sm-10 col-md-8",
      items: [
        {
          itemKey: "privacy",
          className: "",
          config: {
            type: "markdown",
            markdownUrl: "/markdowns/privacy.md"
          }
        }
      ]
    }]
  }]
}

const accessibilityPage: PageConfig = {
  path: "/accessibility",
  pageKey: "accessibility",
  rows: [{
    key: 'content',
    containerClassName: "my-3 min-vh-50",
    columns: [{
      key: "pCol",
      className: "col-12 col-sm-10 col-md-8",
      items: [
        {
          itemKey: "accessibility",
          className: "",
          config: {
            type: "markdown",
            markdownUrl: "/markdowns/accessibility.md"
          }
        }
      ]
    }]
  }]
}

const disclaimerPage: PageConfig = {
  path: "/disclaimer",
  pageKey: "disclaimer",
  rows: [{
    key: 'content',
    containerClassName: "my-3 min-vh-50",
    columns: [{
      key: "pCol",
      className: "col-12 col-sm-10 col-md-8",
      items: [
        {
          itemKey: "disclaimer",
          className: "",
          config: {
            type: "markdown",
            markdownUrl: "/markdowns/disclaimer.md"
          }
        }
      ]
    }]
  }]
}

const contactPage: PageConfig = {
  path: "/contact",
  pageKey: "contact",
  rows: [{
    key: 'content',
    containerClassName: "my-3 min-vh-50",
    columns: [{
      key: "pCol",
      className: "col-12 col-sm-10 col-md-8",
      items: [
        {
          itemKey: "contact",
          className: "",
          config: {
            type: "markdown",
            markdownUrl: "/markdowns/contact.md"
          }
        }
      ]
    }]
  }]
}

export const pagesConfig: PagesConfig = {
  defaultRoutes: defaultRoutes,
  pages: [
    landingPage,
    resultsPage,
    surveyOverviewPage('/home'),
    settingsPage,
    faqPage,
    aboutPage,
    aboutSelftestStudyPage('/about-selfteststudy'),
    contactPage,
    privacyPage,
    accessibilityPage,
    disclaimerPage,
    igasonderzoekPage('/igasonderzoek')
  ]
}
