import React, { useEffect, useState } from 'react';
import { studyAPI } from 'case-web-app-core';
import { ReportHistory } from 'case-web-app-core/build/api/types/studyAPI';
import { MarkdownRenderer } from 'case-web-ui';
import { useTranslation } from 'react-i18next';

interface IsInSelfSwabbingStudyProps {
  pageKey: string;
}


const studyKey = process.env.REACT_APP_DEFAULT_STUDY_KEY ? process.env.REACT_APP_DEFAULT_STUDY_KEY : 'default';

const IsInSelfSwabbingStudy: React.FC<IsInSelfSwabbingStudyProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [isPartOfStudy, setIsPartOfStudy] = useState(false);

  const { t, } = useTranslation([props.pageKey, 'global']);

  useEffect(() => {
    fetchReportHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchReportHistory = async () => {
    if (loading) { return; }
    setLoading(true);
    try {
      const resp = await studyAPI.getReportsForUser(
        [studyKey],
        undefined,
        undefined,
        undefined,
        undefined,
      );
      // console.log(resp.data)
      checkIfPartOfStudy(resp.data);
    } catch (e: any) {
      console.error(e.response);
      return;
    } finally {
      setLoading(false);
    }
  }

  const checkIfPartOfStudy = (reports?: ReportHistory) => {
    if (!reports?.reports) {
      setIsPartOfStudy(false);
      return
    }

    const entryReport = reports.reports.find(report => report.key === "selfSwabbingEntry");
    if (entryReport === undefined) {
      setIsPartOfStudy(false);
      return
    }

    const exitReport = reports.reports.find(report => report.key === "selfSwabbingQuit");
    if (exitReport !== undefined) {
      setIsPartOfStudy(false);
      return
    }
    setIsPartOfStudy(true);
  }

  if (!isPartOfStudy) {
    return null;
  }

  return (
    <div className='border-top-2 border-primary py-2'>
      <MarkdownRenderer
        markdown={t('isInSelfwabbingStudyBadge')}
      />
    </div>
  );
};

export default IsInSelfSwabbingStudy;
