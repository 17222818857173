import React from 'react';
import ClientMotoBar from './ClientMotoBar';
import LogoBar from './LogoBar';

interface RIVMHeaderProps {
}

const RIVMHeader: React.FC<RIVMHeaderProps> = (props) => {
  return (
    <React.Fragment>
      <LogoBar />
      <ClientMotoBar />
    </React.Fragment>
  );
};

export default RIVMHeader;
