import { PageConfig, PageItem } from "case-web-app-core/build/types/pagesConfig";
import { infoPageLayout } from "../../layout/pages/infoPageLayout";
import { simpleRowColLayout } from "../../layout/rows/simpleRowColLayout";


const sidebarItems: PageItem[] = [
  {
    "itemKey": "global:cardBeforeLogin",
    hideWhen: 'auth',
    "className": "d-none d-lg-block mt-3",
    "config": {
      "type": "imageCard",
      "action": {
        "type": "openDialog",
        "value": "signup"
      },
      "imageSrc": "/images/ANP-407855406-1024.jpg",
      "showActionBtn": true
    }
  },
  {
    "itemKey": "global:cardAfterLogin",
    hideWhen: 'unauth',
    "className": "d-none d-lg-block mt-3",
    "config": {
      "type": "imageCard",
      "action": {
        "type": "navigate",
        "value": "/home"
      },
      "imageSrc": "/images/ANP-407855406-1024.jpg",
      "showActionBtn": true
    }
  }
]


export const aboutSelftestStudyPage = (path: string): PageConfig => {
  return infoPageLayout({
    path: path,
    pageKey: 'aboutSelfteststudy',
    sideBarItems: [
      ...sidebarItems
    ],
    bottomRows: [
      simpleRowColLayout({
        rowKey: 'faq',
        items: [
          {
            colClassName: 'col-12 my-3',
            "itemKey": "topItem",
            "className": "",
            "config": {
              "type": "simpleCard",
              "variant": "h2"
            }
          },
          {
            colClassName: 'col-12 mb-3',
            "itemKey": "selftestStudyFAQ",
            "className": "",
            "config": {
              "type": "accordionList",
              "accordionCtrlsKey": "accordionControls"
            }
          }
        ]
      })
    ]
  });
}
