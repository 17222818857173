import { CommonResponseComponentProps } from 'case-web-ui/build/components/survey/SurveySingleItemView/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ItemGroupComponent, ResponseItem } from 'survey-engine/data_types';
import { RootState } from 'case-web-app-core/build/store/rootReducer';
import { getLocaleStringTextByCode } from './utils';

interface EntryCodeValidatorProps extends CommonResponseComponentProps {
}

const EntryCodeValidator: React.FC<EntryCodeValidatorProps> = (props) => {
  const currentUserID = useSelector((state: RootState) => state.user.currentUser.id);

  const [isLoading, setIsLoading] = useState(false);
  const [codeValid, setCodeValid] = useState(false);
  const [showValidationMsg, setShowValidationMsg] = useState(false);
  const [currentCodeValue, setCurrentCodeValue] = useState('');

  const [response, setResponse] = useState<ResponseItem | undefined>(props.prefill);
  const [touched, setTouched] = useState(false);


  const validCodeMessage = (props.compDef as ItemGroupComponent).items.find(item => item.key === 'success');
  const invalidCodeMessage = (props.compDef as ItemGroupComponent).items.find(item => item.key === 'wrong');

  useEffect(() => {
    if (touched) {
      const timer = setTimeout(() => {
        props.responseChanged(response);
      }, 200);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);


  const validateEntrCode = async () => {
    setIsLoading(true)

    const validatorURL = new URL(process.env.REACT_APP_ENTRY_CODE_VALIDATION_URL ? process.env.REACT_APP_ENTRY_CODE_VALIDATION_URL : '');
    const apiKey = process.env.REACT_APP_SELF_SWABBING_SERVICE_API_KEY ? process.env.REACT_APP_SELF_SWABBING_SERVICE_API_KEY : "";

    validatorURL.searchParams.append('code', currentCodeValue);
    validatorURL.searchParams.append('uid', currentUserID ? currentUserID : '');

    try {
      const response = await fetch(validatorURL.toString(), {
        headers: {
          'Api-Key': apiKey,
        }
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error);
      }
      setCodeValid(true)
      const newResponse = {
        key: props.compDef.key ? props.compDef.key : 'no key found',
        items: [
          { key: 'ic', value: currentCodeValue },
        ]
      };
      setResponse(newResponse);
    } catch (err: any) {
      console.error(err)
      setCodeValid(false)
      setResponse(undefined);
    } finally {
      setIsLoading(false);
      setShowValidationMsg(true)
    }
  }

  const checkBtnDisabled = (): boolean => {
    return isLoading || currentCodeValue.length < 6;
  }

  const renderValidationMsg = () => {
    if (!showValidationMsg || !validCodeMessage || !invalidCodeMessage) {
      return null;
    }



    return <div className='mt-1'>
      {codeValid ?
        <p className='text-success'>{getLocaleStringTextByCode(validCodeMessage.content, props.languageCode)}</p> :
        <p className='text-danger'>{getLocaleStringTextByCode(invalidCodeMessage.content, props.languageCode)}</p>}
    </div>
  }

  return (
    <div>
      <div className='input-group'>
        <input className='form-control' type="text"
          value={currentCodeValue}
          onChange={(event) => {
            const v = event.target.value;
            setShowValidationMsg(false);
            setResponse(undefined);
            setCurrentCodeValue(v);
            setTouched(true);
          }}
        ></input>
        <button
          type='button'
          className='btn btn-primary'
          disabled={checkBtnDisabled()}
          onClick={() => {
            validateEntrCode()
          }}
        >
          Verify Code
          {isLoading ?
            <div className="ms-1 spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div> : null
          }

        </button>
      </div>
      {renderValidationMsg()}
    </div>
  );
};

export default EntryCodeValidator;
