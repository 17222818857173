import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classes from './ClientMotoBar.module.scss';
import { containerClassName } from 'case-web-ui';

const ClientMotoBar: React.FC = () => {
  const { t } = useTranslation(['header']);

  return (
    <div className="bg-secondary">
      <div className={containerClassName}>
        <div className={[classes.motoText].join(' ')}>
          <Trans t={t} i18nKey="clientMotoBar">
            <span className="fw-bold">
              {'...'}
            </span>
            <span className="fst-italic ms-1">
              {'...'}
            </span>
            <span>
              {'...'}
            </span>
          </Trans>
        </div>
      </div>
    </div>
  );
};

export default ClientMotoBar;
