import { PageConfig } from "case-web-app-core/build/types/pagesConfig"
import { casePage } from "./case"
import { controlePage } from "./controle"
import { controlRegistrationPage } from "./controlRegistration"

export const igasonderzoekPage = (path: string): PageConfig => {
  const subPages: PageConfig[] = [
    controlRegistrationPage(`${path}/meedoen`),
    controlePage(`${path}/controle`),
    casePage(`${path}/case`)
  ]

  return {
    path: path,
    pageKey: 'igasonderzoek',
    hideTitleBar: true,
    rows: [],
    subPages: {
      defaultRoutes: {
        auth: '/home',
        unauth: '/home'
      },
      pages: subPages,
    },
  }
}
