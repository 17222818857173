import { PageConfig } from "case-web-app-core/build/types/pagesConfig"

export const controlRegistrationPage = (path: string): PageConfig => {
  return {
    path: path,
    pageKey: "igasonderzoek/meedoen",
    rows: [{
      key: 'content',
      fullWidth: true,
      columns: [{
        key: 'colKey',
        className: 'p-0',
        items: [
          {
            itemKey: '1',
            config: {
              type: 'extension',
              config: {
                type: 'igasonderzoekControlRegistration',
              }
            }
          }
        ]
      }]
    },
    ]
  }
}
