import { CommonResponseComponentProps } from 'case-web-ui/build/components/survey/SurveySingleItemView/utils';
import React, { useEffect, useState } from 'react';
import { ItemGroupComponent, ResponseItem } from 'survey-engine/data_types';
import { getLocaleStringTextByCode } from './utils';

interface AddressProps extends CommonResponseComponentProps {
}

interface AddressInfos {
  street: string;
  nr: string;
  city: string;
  zip: string;
}

const validateAddress = (addr: AddressInfos): boolean => {
  if (
    addr.zip === '' ||
    addr.city === '' ||
    addr.street === '' ||
    addr.nr === ''
  ) {
    return false
  }
  return true;
}

const Address: React.FC<AddressProps> = (props) => {
  const [response, setResponse] = useState<ResponseItem | undefined>(props.prefill);
  const [touched, setTouched] = useState(false);

  const [currentAddress, setCurrentAddress] = useState<AddressInfos>({
    city: '',
    street: '',
    nr: '',
    zip: ''
  })

  useEffect(() => {
    if (touched) {
      const timer = setTimeout(() => {
        props.responseChanged(response);
      }, 500);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    setTouched(true);
    if (!validateAddress(currentAddress)) {
      setResponse(undefined);
      return;
    }
    const newResponse = {
      key: props.compDef.key ? props.compDef.key : 'no key found',
      items: [
        { key: 'street', value: currentAddress.street },
        { key: 'nr', value: currentAddress.nr },
        { key: 'city', value: currentAddress.city },
        { key: 'zip', value: currentAddress.zip },
      ]
    }
    setResponse(newResponse)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAddress])


  const streetTexts = (props.compDef as ItemGroupComponent).items.find(item => item.key === 'street');
  const streetLabel = getLocaleStringTextByCode(streetTexts?.content, props.languageCode)
  const streetPlaceholder = getLocaleStringTextByCode(streetTexts?.description, props.languageCode)

  const nrTexts = (props.compDef as ItemGroupComponent).items.find(item => item.key === 'nr');
  const nrLabel = getLocaleStringTextByCode(nrTexts?.content, props.languageCode)
  const nrPlaceholder = getLocaleStringTextByCode(nrTexts?.description, props.languageCode)

  const cityTexts = (props.compDef as ItemGroupComponent).items.find(item => item.key === 'city');
  const cityLabel = getLocaleStringTextByCode(cityTexts?.content, props.languageCode)
  const cityPlaceholder = getLocaleStringTextByCode(cityTexts?.content, props.languageCode)

  const zipTexts = (props.compDef as ItemGroupComponent).items.find(item => item.key === 'zip');
  const zipLabel = getLocaleStringTextByCode(zipTexts?.content, props.languageCode)
  const zipPlaceholder = getLocaleStringTextByCode(zipTexts?.content, props.languageCode)

  return (
    <div className="row g-2">
      <div className="col-12 ">
        <label htmlFor="inputAddress" className="form-label">{streetLabel}</label>
        <input type="text" className="form-control" id="inputAddress" placeholder={streetPlaceholder}
          value={currentAddress.street}
          onChange={(event) => {
            const value = event.target.value;
            setCurrentAddress(prev => {
              return {
                ...prev,
                street: value
              }
            })
          }}
        />
      </div>

      <div className="col-12 ">
        <label htmlFor="hnr" className="form-label">{nrLabel}</label>
        <input type="text" className="form-control" id="hnr" placeholder={nrPlaceholder}
          value={currentAddress.nr}
          onChange={(event) => {
            const value = event.target.value;
            setCurrentAddress(prev => {
              return {
                ...prev,
                nr: value
              }
            })
          }}
        />
      </div>

      <div className="col-12 col-sm-3">
        <label htmlFor="zip" className="form-label">{zipLabel}</label>
        <input type="text" className="form-control" id="zip" placeholder={zipPlaceholder}
          value={currentAddress.zip}
          onChange={(event) => {
            const value = event.target.value;
            setCurrentAddress(prev => {
              return {
                ...prev,
                zip: value
              }
            })
          }}
        />
      </div>

      <div className="col-12 col-sm-9">
        <label htmlFor="city" className="form-label">{cityLabel}</label>
        <input type="text" className="form-control" id="city" placeholder={cityPlaceholder}
          value={currentAddress.city}
          onChange={(event) => {
            const value = event.target.value;
            setCurrentAddress(prev => {
              return {
                ...prev,
                city: value
              }
            })
          }}
        />
      </div>
    </div>
  );
};

export default Address;
