import { FooterConfig } from "case-web-app-core/build/types/footerConfig";

export const footerConfig: FooterConfig = {
  columns: [
    {
      columnKey: 'service',
      items: [
        {
          type: "internal",
          itemKey: "contact",
          value: "/contact"
        },
        {
          type: "external",
          itemKey: "twitter",
          value: "https://twitter.com/rivm",
          iconClass: "fab fa-twitter"
        },
        {
          type: "external",
          itemKey: "facebook",
          value: "https://www.facebook.com/RIVMnl",
          iconClass: "fab fa-facebook-square"
        },
        {
          type: "external",
          itemKey: "instagram",
          value: "https://www.instagram.com/rivmnl",
          iconClass: "fab fa-instagram"
        },
        {
          type: "external",
          itemKey: "youtube",
          value: "https://www.youtube.com/user/RIVMnl",
          iconClass: "fab fa-youtube-square"
        },
        {
          type: "external",
          itemKey: "linkedin",
          value: "https://nl.linkedin.com/company/rivm",
          iconClass: "fab fa-linkedin"
        },
      ]
    },
    {
      columnKey: 'about',
      items: [
        {
          type: "internal",
          itemKey: "privacy",
          value: "/privacy"
        },
        {
          type: "internal",
          itemKey: "disclaimer",
          value: "/disclaimer"
        },
        {
          type: "internal",
          itemKey: "accessibility",
          value: "/accessibility"
        },
      ]
    }
  ]
}
