import { coreReduxActions, studyAPI } from 'case-web-app-core';
import { RootState } from 'case-web-app-core/build/store/rootReducer';
import { LoadingPlaceholder, MarkdownRenderer, SurveyView } from 'case-web-ui';
import { SurveyAndContextMsg } from 'case-web-ui/build/types/studyAPI';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Survey, SurveyContext, SurveyResponse } from 'survey-engine/data_types';
import { dateLocales } from '../../App';
import UserVerification from '../survey/UserVerification';
import { GenericPageItemProps } from './utils';

interface IgasonderzoekSurveysProps extends GenericPageItemProps {
  surveyKey: string;
  protectWithCode: boolean;
}

const codeCheckURL = new URL(process.env.REACT_APP_IGASONDERZOEK_API ? `${process.env.REACT_APP_IGASONDERZOEK_API}/igasonderzoek/check-code` : '');
const markCodesUsedURL = new URL(process.env.REACT_APP_IGASONDERZOEK_API ? `${process.env.REACT_APP_IGASONDERZOEK_API}/igasonderzoek/code-used` : '');
const apiKey = process.env.REACT_APP_SELF_SWABBING_SERVICE_API_KEY ? process.env.REACT_APP_SELF_SWABBING_SERVICE_API_KEY : "";

const studyKey = 'igasonderzoek';


const IgasonderzoekSurveys: React.FC<IgasonderzoekSurveysProps> = (props) => {
  const { t, i18n } = useTranslation([props.pageKey, 'surveyPage']);
  const dispatch = useDispatch();
  const query = new URLSearchParams(window.location.search);
  const code = query.get("code");

  const [isLoading, setIsLoading] = useState(false);
  const [uiStep, setUIStep] = useState<'codeInput' | 'survey' | 'success' | 'codeError' | 'submitError'>(props.protectWithCode ? 'codeInput' : 'survey');
  const [codeInputValue, setCodeInputValue] = useState('');

  const instanceID = useSelector((state: RootState) => state.config.instanceId);

  const [currentSurvey, setCurrentSurvey] = useState<{
    surveyDef: Survey;
    context?: SurveyContext;
    prefill?: SurveyResponse;
    openedAt: number;
  } | undefined>();
  const [currentSurveyKey, setCurrentSurveyKey] = useState(props.protectWithCode ? '' : props.surveyKey);
  const [currentSurveyResponse, setCurrentSurveyResponse] = useState<SurveyResponse | undefined>(undefined);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(coreReduxActions.appActions.openSurveyMode(undefined));
    return () => {
      dispatch(coreReduxActions.appActions.closeSurveyMode());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkCode = useCallback(async (code: string) => {
    setIsLoading(true);

    try {
      codeCheckURL.searchParams.set('code', code);
      const response = await fetch(codeCheckURL.toString(), {
        headers: {
          'Api-Key': apiKey,
        },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error);
      }
      console.log(data)
      setCurrentSurveyKey(props.surveyKey);
      setUIStep('survey')
    } catch (err: any) {
      console.error(err)
      setUIStep('codeError');
    } finally {
      setIsLoading(false);
    }

  }, [props.surveyKey])

  const markCodeUsed = useCallback(async (code: string) => {
    try {
      markCodesUsedURL.searchParams.set('code', code);
      const response = await fetch(markCodesUsedURL.toString(), {
        headers: {
          'Api-Key': apiKey,
        },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error);
      }
    } catch (err: any) {
      console.error(err)
    }

  }, [])

  const registerTempParticipant = useCallback(async () => {
    try {
      const resp = await studyAPI.registerTempParticipantReq({
        instance: instanceID,
        study: studyKey,
      });
      const data = {
        temporaryParticipantId: resp.data.temporaryParticipantId,
        timestamp: parseInt(resp.data.timestamp)
      };
      return data;
    } catch (e) {
      console.error(e)
    }

  }, [instanceID])

  useEffect(() => {
    if (code === null || code === undefined || code === '') {
      return;
    }
    setCodeInputValue(code);
    checkCode(code);
  }, [code, checkCode])


  useEffect(() => {
    const fetchSurvey = async (surveyKey: string) => {
      setCurrentSurvey(undefined);
      setIsLoading(true);
      try {
        let survey: SurveyAndContextMsg;

        survey = (await studyAPI.getSurveyWithoutLoginReq({
          instance: instanceID,
          study: studyKey,
          survey: surveyKey,
        })).data;


        console.log(survey)
        const now = Math.round(new Date().getTime() / 1000);

        if (!survey.context) {
          survey.context = {}
        }

        setCurrentSurvey({
          surveyDef: survey.survey,
          context: survey.context,
          prefill: survey.prefill,
          openedAt: now,
        })
      } catch (e) {
        console.error(e)
        setUIStep('codeError');
      } finally {
        setIsLoading(false);
      }
    }

    if (currentSurveyKey !== '') {
      fetchSurvey(currentSurveyKey);
    }
  }, [currentSurveyKey, instanceID])


  useEffect(() => {
    if (!currentSurveyResponse) {
      return;
    }

    const submitResponsesWithoutLogin = async (response: SurveyResponse) => {
      setIsLoading(true);
      try {

        const currentTempParticipant = await registerTempParticipant();

        const resp = await studyAPI.submitSurveyResponseForTempParticipantRequest({
          studyKey: studyKey,
          response: response,
          instanceId: instanceID,
          temporaryParticipantId: currentTempParticipant?.temporaryParticipantId,
          temporaryParticipantTimestamp: currentTempParticipant?.timestamp,
        })
        console.log(resp);

        await markCodeUsed(codeInputValue);
        setUIStep('success');
      } catch (e) {
        console.error(e)
        setUIStep('submitError');
      }
      finally {
        setIsLoading(false);
      }
    }

    submitResponsesWithoutLogin(currentSurveyResponse);
  }, [currentSurveyResponse, instanceID, registerTempParticipant, codeInputValue, markCodeUsed]);

  if (isLoading) {
    return <LoadingPlaceholder color='white' minHeight={800} />
  }

  if (uiStep === 'codeInput') {
    return <div className='min-vh-60 d-flex flex-column align-items-center justify-content-center px-2'>
      <div className='bg-grey-1 p-2' style={{ width: 600, maxWidth: 600 }}>
        <label
          className='form-label'
          htmlFor="codeInput">{t('codeInput.label')}</label>
        <input
          className='form-control mb-2'
          name='codeInput' id='codeInput'
          value={codeInputValue}
          autoComplete='off'
          onChange={(event) => {
            const value = event.target.value;
            setCodeInputValue(value);
          }}
          placeholder={`${t('codeInput.placeholder')}`}
        ></input>
        <button className='btn btn-primary'
          type='button'
          disabled={codeInputValue.length < 1}
          onClick={() => {
            checkCode(codeInputValue)
          }}
        >
          {t('codeInput.btn')}
        </button>
      </div>
    </div>
  }

  if (uiStep === 'codeError') {
    return <div className='min-vh-60 d-flex flex-column align-items-center justify-content-center px-2'>
      <div className='bg-danger-light p-2' style={{ width: 600, maxWidth: 600 }}>
        <MarkdownRenderer
          markdown={t('codeCheckError.msg')}
        ></MarkdownRenderer>
      </div>
      <button
        className='btn btn-primary mt-2'
        onClick={() => {
          setUIStep('codeInput');
        }}
      >
        {t('codeCheckError.retryBtn')}
      </button>
    </div>
  }

  if (uiStep === 'submitError') {
    return <div className='min-vh-60 d-flex flex-column align-items-center justify-content-center px-2'>
      <div className='bg-danger-light p-2' style={{ width: 600, maxWidth: 600 }}>
        {t('surveySubmission.failedMsg')}
      </div>
      <button
        className='btn btn-primary mt-2'
        onClick={() => {
          setCurrentSurveyResponse(prev => {
            if (prev === undefined) {
              console.error('survey response undefined, cannot submit again');
              return;
            }
            return {
              ...prev
            }
          })
        }}
      >
        {t('surveySubmission.retryBtn')}
      </button>
    </div>
  }

  if (uiStep === 'success') {
    return <div className='min-vh-60 d-flex flex-column align-items-center justify-content-center px-2'>
      <div className='bg-success-light p-2' style={{ width: 600, maxWidth: 600 }}>
        {t('surveySubmission.successMsg')}
      </div>
    </div>
  }


  if (currentSurvey === undefined) {
    return <LoadingPlaceholder color='white' minHeight={800} />
  }

  return (
    <div className=" py-3">
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="" style={{ maxWidth: 700 }}>
            <SurveyView
              submitBtnText={t('surveyPage:submitBtn')}
              backBtnText=""
              nextBtnText=""
              languageCode={i18n.language}
              dateLocales={dateLocales}
              customResponseComponents={[
                {
                  name: 'input:userVerification',
                  component: UserVerification,
                },
              ]}
              invalidResponseText={t('surveyPage:notValidQuestion')}
              survey={currentSurvey.surveyDef} onSubmit={(responses, version: string) => {
                // console.log(responses)
                const now = Math.round(new Date().getTime() / 1000);

                const response = {
                  key: currentSurvey.surveyDef.surveyDefinition.key,
                  responses: [...responses],
                  versionId: version,
                  submittedAt: now,
                  openedAt: currentSurvey.openedAt,
                  context: {
                    engineVersion: process.env.REACT_APP_SURVEY_ENGINE_VERSION,
                    language: i18n.language,
                    code: codeInputValue,
                  }
                }
                setCurrentSurveyResponse(response);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IgasonderzoekSurveys;
