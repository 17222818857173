import { PageConfig } from "case-web-app-core/build/types/pagesConfig"

export const surveyOverviewPage = (path: string): PageConfig => {
  return {
    path: path,
    hideWhen: 'unauth',
    pageKey: "home",
    rows: [{
      key: 'teaserImage',
      fullWidth: true,
      columns: [{
        key: 'colKey',
        className: 'p-0',
        items: [{
          itemKey: 'teaserImage',
          config: {
            type: 'teaserImage',
            image: {
              url: '/images/ANP-371602781-1024.jpg',
              height: 367
            },
            textBox: {
              className: 'col col-md-6 col-lg-4 offset-0 offset-lg-1'
            }
          }
        }]
      }]
    },
    {
      key: 'main',
      containerClassName: "py-3 min-vh-50",
      columns: [
        {
          key: 'surveyCol',
          className: 'col-12 col-lg-6 offset-lg-1',
          items: [
            {
              itemKey: 'isInSelfSwabbing',
              className: '',
              config: {
                type: 'extension',
                config: {
                  type: 'isInSelfSwabbingStudy',
                }
              }
            },
            {
              itemKey: "surveyList",
              className: "",
              config: {
                type: "surveyList"
              }
            }]
        },
        {
          key: 'sideCol',
          className: 'col-12 col-md-10 col-lg-4 pt-3 pt-lg-0',
          items: [
            {
              itemKey: 'referencesBox',
              config: {
                type: 'linkList',
                links: [
                  {
                    linkKey: 'faqLink',
                    type: 'internal',
                    value: '/faq'
                  }, {
                    linkKey: 'resultsLink',
                    type: 'internal',
                    value: '/results'
                  }, {
                    linkKey: 'aboutLink',
                    type: 'internal',
                    value: '/about'
                  },
                ]
              }
            },
            {
              itemKey: 'quickSettingsBox',
              config: {
                type: 'linkList',
                links: [
                  {
                    linkKey: 'settingsLink',
                    type: 'internal',
                    value: '/settings'
                  },
                  {
                    linkKey: 'manageProfiles',
                    type: 'dialog',
                    value: 'manageProfiles'
                  }
                ]
              }
            }
          ]
        }
      ]
    }]
  }
}
