import { LoadingPlaceholder, MarkdownRenderer, SurveyView } from "case-web-ui";
import React, { useEffect, useState } from "react";
import UserVerification from "../survey/UserVerification";
import { GenericPageItemProps } from "./utils";

import { Survey, SurveySingleItemResponse } from "survey-engine/data_types";
import { coreReduxActions, studyAPI } from "case-web-app-core";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { dateLocales } from "../../App";
import { RootState } from "case-web-app-core/build/store/rootReducer";
import { SurveyAndContextMsg } from "case-web-ui/build/types/studyAPI";

interface IgasonderzoekControlRegistrationProps extends GenericPageItemProps {
}


interface RegistrationFormData {
  email: string;
  controlResponse: string;
  children: Array<{
    birthyear: number;
    birthmonth: number;
    gender: string;
  }>
}

const studyKey = 'igasonderzoek';
const surveyKey = 'ControleRegistration';


const parseInfosFromRegistrationForm = (resp: SurveySingleItemResponse[]): RegistrationFormData => {
  console.log(resp)

  const formInputs: RegistrationFormData = {
    children: [],
    email: '',
    controlResponse: ''
  };

  const emailItem = resp.find(item => item.key.includes('Email'));
  if (emailItem !== undefined) {
    try {
      const ei = emailItem.response!.items![0].value;

      if (ei !== undefined) {
        formInputs.email = ei;
      }
    } catch (err) {
      console.error(err);
    }
  }

  const controlItem = resp.find(item => item.key.includes('UV'));
  if (controlItem !== undefined) {
    try {
      const ci = controlItem.response!.items![0].items![0].value;

      if (ci !== undefined) {
        formInputs.controlResponse = ci;
      }
    } catch (err) {
      console.error(err);
    }
  }

  const numberOfChildrenItem = resp.find(item => item.key.includes('ChildCount'));
  let childCount = 0;
  if (numberOfChildrenItem !== undefined) {
    try {
      childCount = parseInt(numberOfChildrenItem.response!.items![0].items![0].key);
    } catch (err) {
      console.error(err);
    }
  }

  for (let i = 0; i < childCount; i++) {
    const birthyearItem = resp.find(item => item.key.includes(`kind${i + 1}.geboortejaar`));
    const birthmonthItem = resp.find(item => item.key.includes(`kind${i + 1}.geboortemaand`));
    const genderItem = resp.find(item => item.key.includes(`kind${i + 1}.geslacht`));

    const child = {
      birthyear: 0,
      birthmonth: 0,
      gender: ''
    }

    if (birthyearItem !== undefined) {
      try {
        child.birthyear = parseInt(birthyearItem.response!.items![0].items![0].key);
      } catch (err) {
        console.error(err);
      }
    }

    if (birthmonthItem !== undefined) {
      try {
        child.birthmonth = parseInt(birthmonthItem.response!.items![0].items![0].key);
      } catch (err) {
        console.error(err);
      }
    }

    if (genderItem !== undefined) {
      try {
        child.gender = genderItem.response!.items![0].items![0].key;
      } catch (err) {
        console.error(err);
      }
    }

    formInputs.children.push(child);
  }

  return formInputs;
}

const registrationURL = new URL(process.env.REACT_APP_IGASONDERZOEK_API ? `${process.env.REACT_APP_IGASONDERZOEK_API}/igasonderzoek/registration` : '');
const apiKey = process.env.REACT_APP_SELF_SWABBING_SERVICE_API_KEY ? process.env.REACT_APP_SELF_SWABBING_SERVICE_API_KEY : "";

const IgasonderzoekControlRegistration: React.FC<IgasonderzoekControlRegistrationProps> = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation([props.pageKey, 'surveyPage']);

  const [formData, setFormData] = useState<undefined | RegistrationFormData>();
  const [isLoading, setIsLoading] = useState(false);

  const [uiStep, setUIStep] = useState<'survey' | 'success' | 'loadingError' | 'submitError'>('survey');
  const instanceID = useSelector((state: RootState) => state.config.instanceId);

  const [currentSurvey, setCurrentSurvey] = useState<{
    surveyDef: Survey;
  } | undefined>();
  const [currentSurveyKey, setCurrentSurveyKey] = useState(surveyKey);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(coreReduxActions.appActions.openSurveyMode(undefined));
    return () => {
      dispatch(coreReduxActions.appActions.closeSurveyMode());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchSurvey = async (surveyKey: string) => {
      setCurrentSurvey(undefined);
      setIsLoading(true);
      try {
        let survey: SurveyAndContextMsg;

        survey = (await studyAPI.getSurveyWithoutLoginReq({
          instance: instanceID,
          study: studyKey,
          survey: surveyKey,
        })).data;

        if (!survey.context) {
          survey.context = {}
        }

        setCurrentSurvey({
          surveyDef: survey.survey,
        })
      } catch (e) {
        console.error(e)
        setUIStep('loadingError');
      } finally {
        setIsLoading(false);
      }
    }

    if (currentSurveyKey !== '') {
      fetchSurvey(currentSurveyKey);
      console.log('trying to reload survey');
    }
  }, [currentSurveyKey, instanceID])

  useEffect(() => {
    if (!formData) {
      return;
    }

    const submitRegistrationForm = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(registrationURL.toString(), {
          method: 'POST',
          headers: {
            'Api-Key': apiKey,
          },
          body: JSON.stringify(formData),
        });
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.error);
        }
        console.log(data)
        setUIStep('success');
      } catch (err: any) {
        console.error(err)
        setUIStep('submitError');

      } finally {
        setIsLoading(false);
      }
    }
    submitRegistrationForm();
  }, [formData])


  if (isLoading) {
    return <LoadingPlaceholder color='white' minHeight={800} />
  }

  if (uiStep === 'loadingError') {
    return <div className='min-vh-60 d-flex flex-column align-items-center justify-content-center px-2'>
      <div className='bg-danger-light p-2' style={{ width: 600, maxWidth: 600 }}>
        <MarkdownRenderer
          markdown={t('loadingError.msg')}
        ></MarkdownRenderer>
      </div>
      <button
        className='btn btn-primary mt-2'
        onClick={() => {
          setCurrentSurveyKey('');
          setTimeout(() => {
            setCurrentSurveyKey(surveyKey);
          }, 500);
          setUIStep('survey');
        }}
      >
        {t('loadingError.retryBtn')}
      </button>
    </div>
  }

  if (uiStep === 'submitError') {
    return <div className='min-vh-60 d-flex flex-column align-items-center justify-content-center px-2'>
      <div className='bg-danger-light p-2' style={{ width: 600, maxWidth: 600 }}>
        {t('surveySubmission.failedMsg')}
      </div>
      <button
        className='btn btn-primary mt-2'
        onClick={() => {
          setFormData(prev => {
            if (prev === undefined) {
              console.error('survey response undefined, cannot submit again');
              return;
            }
            return {
              ...prev
            }
          })
        }}
      >
        {t('surveySubmission.retryBtn')}
      </button>
    </div>
  }

  if (uiStep === 'success') {
    return <div className='min-vh-60 d-flex flex-column align-items-center justify-content-center px-2'>
      <div className='bg-success-light p-2' style={{ width: 600, maxWidth: 600 }}>
        {t('surveySubmission.successMsg')}
      </div>
    </div>
  }


  if (currentSurvey === undefined) {
    return <LoadingPlaceholder color='white' minHeight={800} />
  }

  return (
    <div className=" py-3">
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="" style={{ maxWidth: 700 }}>
            <SurveyView
              submitBtnText="Inschrijven"
              backBtnText=""
              nextBtnText=""
              languageCode={i18n.language}
              dateLocales={dateLocales}
              invalidResponseText={t('surveyPage:notValidQuestion')}
              survey={currentSurvey.surveyDef}
              customResponseComponents={[
                {
                  name: 'input:userVerification',
                  component: UserVerification,
                },
              ]}
              onSubmit={(responses) => {
                const regisrationInfos = parseInfosFromRegistrationForm(responses);
                setFormData(regisrationInfos);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IgasonderzoekControlRegistration;
