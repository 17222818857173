import React from 'react';
import logo from "../../assets/images/logo.svg";
import { useTranslation, Trans } from 'react-i18next';
import styles from './LogoBar.module.scss';
import clsx from 'clsx';

const LogoBar: React.FC = () => {
  const { t } = useTranslation(['header']);

  return (
    <div className="d-flex">
      <div className="mt-0" style={{
        marginLeft: "calc(50% - 22px)"
      }}>
        <img src={logo} alt="logo" />
      </div>
      <div className="ms-1 pb-2" style={{
        letterSpacing: "0.001em",
        width: '210px',
        paddingTop: 34,
        lineHeight: '10px',
      }}>
        <Trans t={t} i18nKey="firstWordMark">
          <div className={styles.wordMark}>{'...'}</div>
          <div className={styles.wordMark}>{'...'}</div>
        </Trans>
        <Trans t={t} i18nKey="secondWordMark">
          <div className={clsx('fst-italic', styles.wordMark)}>{'...'}</div>
          <div className={clsx('fst-italic', styles.wordMark)}>{'...'}</div>
        </Trans>



      </div>
      {/*

          <Typography variant={"body2"} className={classes.firstWordMark}>
            {t("app:logo.logoCaptionline1")}
          </Typography>
          <Typography variant={"body2"} className={classes.firstWordMark}>
            {t("app:logo.logoCaptionLine2")}
          </Typography>
          <Typography variant={"body2"} className={classes.secondWordMark}>
            {t("app:logo.logoSubCaptionLine1")}
          </Typography>
          <Typography variant={"body2"} className={[classes.secondWordMark, classes.paddingBottom].join(" ")}>
            {t("app:logo.logoSubCaptionLine2")}
          </Typography>
        </div>
        */}
    </div>

  );
};

export default LogoBar;
